/*TONO OSCURO: #03285a */
/*TONO CLARO: #002866 */


.flex-grow-1 {
    margin: 0 !important;
    font-family: var(--bs-body-font-family) !important;
    font-size: var(--bs-body-font-size) !important;
    font-weight: var(--bs-body-font-weight) !important;
    line-height: var(--bs-body-line-height) !important;
    color: var(--bs-body-color) !important;
    text-align: var(--bs-body-text-align) !important;
    /* background-color: var(--bs-body-bg) !important; */
    /* background-color: #fafbfe !important; */
    background-color: #ffffff !important;/* #e7edff */
    -webkit-text-size-adjust: 100% !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.container-fluid {
    -webkit-box-shadow: 0px 0px 35px 0px rgba(98, 144, 208, 0.15);
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}

.header-sticky {
    -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}

.btn-success {
    color: #fff;
    background-color: #002866 !important;
    border-color: #002866 !important;
}

.btn-success:hover {
    color: #fff !important;
    background-color: #03285a !important;
    border-color: #03285a !important;
}

.btn-info {
    color: #fff !important;
    background-color: #39afd1 !important;
    border-color: #39afd1 !important;
}

.btn-danger-theme {
    color: #fff;
    background-color: #fa5c7c !important;
    border-color: #fa5c7c !important;
}


.btn-danger-theme:hover {
    color: #fff;
    background-color: #d54e69 !important;
    border-color: #c84a63 !important;
}
.btn-warning-theme {
    color: #fff;
    background-color: #ffbc00 !important;
    border-color: #ffbc00 !important;
}

.btn-warning-theme:hover {
    color: #fff;
    background-color: #cc9600 !important;
    border-color: #cc9600 !important;
}

.btn-info:hover {
    color: #fff !important;
    background-color: #3095b2 !important;
    border-color: #2e8ca7 !important;
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:hover {

    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.bg-info-theme {
    --bs-bg-opacity: 1;
    color: #fff;
    background-color: #3095b2 !important;
    border-color: #2e8ca7 !important;
}

.bg-primary-theme {
    --bs-bg-opacity: 1;
    color: #fff;
    background-color: #7e57c2 !important;
    border-color: #7e57c2 !important;
}
.bg-yellow-theme {
    background-color: #ffbc00 !important;
    border-color:  #ffbc00 !important;
    color: #fff !important;
}
.bg-success-theme {

    background-color: #002866 !important;
    border-color: #002866 !important;
    color: #fff;
}

.bg-danger-theme {
    background-color: #d54e69 !important;
    border-color: #c84a63 !important;
    color: #fff;
}
.bg-purple-theme{
    background-color: #727cf5 !important;
    border-color: #727cf5 !important;
    color: #fff;
}


.btn-close {
    color: #fff !important;
    background-color: #fff !important;
}

.card-theme {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    border: none;
    -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    margin-bottom: 24px;
}

.card-body-theme {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem;
}

.icon-dashboard {
    font-size: 50px;
}

.img-thumbnail {
    background-color: transparent !important;
    border: none !important;
}

.sidebar-dark {
    background-color: #002866;
    /* width: 290px !important; */
}

.btn-outline-success-pk:hover {
    color: #fff !important;
    background-color: #002866 !important;

}

.btn-outline-success-pk {
    color: #002866 ;
    border-color: #002866 !important;
      
}

.btn-outline-success-pk.active {
    color: #fff !important;
    background-color: #002866 !important;
}

.badge-card-dashboard {

    color: #fff !important;
    display: block !important;
    margin-top: 20px !important;
    width: 100px !important;
    background-color: none !important;
}

.theme-hover:hover {
    transform: scale(1.1);
}

.widget-flat {
    transition: transform 0.3s ease-in-out;

}

.icon-dashboard-coreui {

    height: 60px !important;
    width: 100% !important;
    margin-right: -95 !important;
}

/******loader******/
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Fondo semi-transparente oscuro */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    /* Asegura que el overlay esté por encima del contenido */
}

.loading {
    position: fixed;
    float: left;
    top: 50%;
    left: 50%;
    height: 120px;
    padding: 0px;
    width: 160px;
    margin-top: -50px;
    margin-left: -70px;
    box-sizing: border-box;
    border-left: 2px solid #53ffce;
    border-bottom: 2px solid #53ffce;
    padding: 10px;
    box-sizing: border-box;
}

@keyframes loading {
    0% {
        background-color: #cd0a00;
    }

    30% {
        background-color: #fa8a00;
    }

    50% {
        height: 100px;
        margin-top: 0px;
    }

    80% {
        background-color: #91d700;
    }

    100% {
        background-color: #cd0a00;
    }
}

.loading-bar {
    height: 10px;
    width: 30px;
    background-color: #91d700;
    display: inline-block;
    margin-top: 90px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.loading-1 {
    animation: loading 2.5s infinite 0s;
}

.loading-2 {
    animation: loading 2.5s infinite 0.5s;
}

.loading-3 {
    animation: loading 2.5s infinite 1s;
}

.loading-4 {
    animation: loading 2.5s infinite 1.5s;
}


/* body {
    background-color: #313a46 ;
  } */

/* [data-amplify-router-content] {
    height: 669px !important;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding-top: 18px !important;
} */


[data-amplify-authenticator] [data-amplify-router] {
    border-radius: 4%;
}

.line-center {
    width: 88%;
    /* Establece el ancho del div al 50% */
    margin: 0 auto;
    /* Centra el div horizontalmente */
    text-align: center;
    /* Alinea el contenido del div al centro */
}


.btn-pk {
    color: #fff;
    background-color: #002866 !important;
    border-color: #002866 !important;
    font-size: 18px !important;
}

/* .btn-pk:hover {
    color: #fff;
    background-color: #00783F !important;
    border-color: #00783F !important;
} */

.btn-pk.active {
    background-color: #289799 !important;
    border-color: #289799 !important;
}

.breadcrumb-pk{

    font-size: 18px !important; 
    color: var(--cui-secondary-color) !important;
}
.dateRange-pk{
    color: #39f !important;
}

.header-sticky, .container-fluid { 
    box-shadow: none !important;
}

.tr-pk th, .tr-pk td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-bottom: 1px solid rgb(47 53 65 / 68%) !important;
}

/* .tr-pk th , .tr-pk td{
    background-color: #002866 !important;
    border-color:  #002866 !important;
    color: #fff !important;
  
  } */
  .tr-pk2 th , .tr-pk2 td{
    background-color: #39afd1 !important; 
    border-color:  #39afd1 !important; 
    color: #fff;
  }
  .tr-pk3 th , .tr-pk3 td{
    background-color: #ffbc00 !important;
    border-color:  #ffbc00 !important;
    color: #fff !important;
  }
  .tr-pk4 th , .tr-pk4 td{
    background-color: #39afd1 !important;
    border-color:  #39afd1 !important;
    color: #fff !important;
    opacity: .65 !important;
  }


  .tr-pk5 th , .tr-pk5 td{
    background-color:   #fa5c7c !important;
    border-color:    #fa5c7c !important;
    color: #fff;

  }

  .tr-pk-head th{
    /* background-color:   #737f8d  !important;
    border-color:    #737f8d  !important; */
    background-color:   #002866    !important;
    border-color:    #002866    !important; 
    color: #fff;
    font-size: 15px;
  }
  .tr-pk-head-monitoreo th{

    background-color:   #737f8d    !important;
    border-color:    #737f8d    !important; 
    color: #fff;
    font-size: 17px;
  }
  .tbody-pk{
    font-size: 14px;
  }
  .text-secondary-pk{
    color:rgb(9 9 10 / 68%);
  }

  .btn {
    font-weight: bold !important;
  }
.legend-timeline-pk{

    height: 12px;
    width: 12px;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 2px;
}
.legend-pk-success{
    color: #002866 !important;
    background-color: #002866 !important;
    border-color: #002866 !important;
}
.legend-pk-equipo{
    color: #119972 !important;
    background-color: #119972 !important;
    border-color: #119972 !important;
}
.legend-pk-programado{
    color: #795548  !important;
    background-color: #795548  !important;
    border-color: #795548  !important;
}
.legend-pk-organizacionales{
    color: #7e57c2  !important;
    background-color: #7e57c2  !important;
    border-color: #7e57c2  !important;
}
.legend-pk-calidad{
    color: #009688  !important;
    background-color: #009688 !important;
    border-color: #009688 !important;
}
.legend-pk-planificadas{
    color: #727cf5  !important;
    background-color: #727cf5 !important;
    border-color: #727cf5 !important;
}
.legend-pk-rutinarias{
    color: #6c757d  !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}

.legend-pk-info{
    color: #39afd1 !important;
    background-color: #39afd1 !important;
    border-color: #39afd1 !important;
}
.legend-pk-danger{
    color: #fa5c7c !important;
    background-color: #fa5c7c !important;
    border-color: #fa5c7c !important;
}
.legend-pk-yellow{
    color: #ffbc00 !important;
    background-color: #ffbc00 !important;
    border-color: #ffbc00 !important;
}
.legend-pk-primary{
    color: #ffbc00 !important;
    background-color: #ffbc00 !important;
    border-color: #ffbc00 !important;
}
.dataTables_filter{
    margin-bottom: 10px;
}
.myTable input{
    border: solid 2px #313a46 !important;
}

.sidebar-pk  .nav-link:hover {
    background-color: #8a8a8a !important;
}
.sidebar-pk  .nav-link.active {
    background-color: #8a8a8a !important;
}

.sidebar-nav .nav-group.show .nav-group-toggle, .sidebar-nav .nav-link  {
    color: rgb(255 255 255 ) !important;
}

.sidebar-toggler {
font-size: 200px !important;
color: rgb(255 255 255 ) !important;
}
.sidebar-toggler:before {
    font-size: 200px !important;
color: rgb(255 255 255 ) !important;
}
.amplify-button {
    background-color: #002866 ;
    color: #fff;
}
.amplify-button:hover {
    background-color: #002866 ;
    color: #fff;
}

.form-control ,.form-select{
    border: solid 1px #002866 !important
}
 .bloqueado-pk {
    pointer-events: none !important;
    opacity: 0.5 !important; 
  }
  .input-find-pk-prod{
    padding-left: 30px;
    margin-left: 22px;
    height: 40px;
    border: solid 2px #727cf5;
    padding-bottom: 1;
    margin-bottom: 3px;

  }
  .swal2-confirm .swal2-styled .swal2-default-outline{
    color: #fff;
    background-color: #002866 !important;
    border-color: #002866 !important;
  }

  .rs-picker-error > .rs-input-group {
    border-color: none !important;
  }